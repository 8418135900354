import React, { useState } from 'react';
import './App.css'; // Importing your custom CSS
import { Container, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material'; // Importing Material-UI components

function App() {
  const [jobTitle, setJobTitle] = useState('');
  const [location, setLocation] = useState('');
  const [results, setResults] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Sample results for testing
    setResults([
      { jobTitle: 'Software Engineer', company: 'ABC Corp', location: 'New York, NY', datePosted: '3 days ago', jobLink: 'https://example.com/job/123' },
      { jobTitle: 'Data Scientist', company: 'XYZ Inc', location: 'Remote', datePosted: '1 week ago', jobLink: 'https://example.com/job/456' },
    ]);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Job Search Interface
      </Typography>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '20px' }}>
          <TextField
            label="Job Title"
            variant="outlined"
            fullWidth
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            required
            style={{ marginBottom: '20px' }}
          />
          <TextField
            label="Location"
            variant="outlined"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Search Jobs
        </Button>
      </form>

      <Typography variant="h5" style={{ marginTop: '30px' }} gutterBottom>
        Search Results
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Job Title</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Date Posted</TableCell>
              <TableCell>Job Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                <TableCell>{result.jobTitle}</TableCell>
                <TableCell>{result.company}</TableCell>
                <TableCell>{result.location}</TableCell>
                <TableCell>{result.datePosted}</TableCell>
                <TableCell>
                  <a href={result.jobLink} target="_blank" rel="noopener noreferrer">
                    View Job
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default App;

